<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>Reward Catalog</h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />                      
              </CCol>
              <CCol md="2">
                   <b-button variant="primary" to="/reward/catalog/create" >Add New</b-button>                    
              </CCol>             
          </CRow>
        </CCardHeader><br/>
        <CCardBody>
          <CRow>
              <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
          </CRow>

          <div class="text-center">
             <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
          </div>

          <div class="table-responsive table">
          <b-table striped hover  :items="items" :fields="fields" sort-by="createDate" sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter">

            <template v-slot:cell(createDate)="{ item }"> 
                 {{ item.createDate | dateWithTime }}
            </template>  

             <template v-slot:cell(userGroupId)="{ item }"> 
                 {{ ListUserGroup[item.userGroupId] }}
            </template>                

            <template v-slot:cell(actions)="{ item }">  
                <b-dropdown variant="info" text="Actions" size="md">   
                <router-link to="#" exact v-on:click.native="actionDelete(item.id)" tag="b-dropdown-item" > Delete </router-link>
                <router-link :to='"/reward/catalog/" + item.id' tag="b-dropdown-item" > Edit </router-link>
                <router-link :to='"/reward/catalog/upload/" + item.id' tag="b-dropdown-item" > Upload Image </router-link> 
                </b-dropdown> 
            </template>

        </b-table>
        </div>
      
        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import RewardService from '@/api/RewardService.js'; 
import TransactService from '@/api/TransactService.js';
import moment from 'moment';

 export default {
    name: "reward_category",
    data() { var date = new Date();
      return { 
        items:[], 
        //ListUserGroup:[],            
        fields: [   
          {            
            key: 'name',
            label : 'Name',
            sortable: true
          },  
          {
            key: 'description',
            label : 'Description',
            sortable: true
          }, 
          {
            key: 'highlights',
            label : 'Highlights',
            sortable: true
          },  
          // {
          //   key: 'userGroupId',
          //   label : 'User Group',
          //   sortable: true
          // },
          {
            key: 'userTier',
            label : 'User Tier',
            sortable: true
          },              
          {
            key: 'pointAmount',
            label : 'Point Amount',
            sortable: true
          },  
          {
            key: 'rewardAmount',
            label : 'Reward Amount',
            sortable: true
          },            
          {
            key: 'categoryName',
            label : 'Reward Category Name',
            sortable: true
          }, 
          {
            key: 'rewardType',
            label : 'Reward Type',
            sortable: true
          },           
        
          {
            key: 'createDate',
            label : 'Created Date',
            sortable: true
          },
          {            
            key: 'actions',
            label: 'Actions'         
          }       
        ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
        seen:false,
        msg :'',
        color:'', 
        spinner:false,
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        passOnlyString(value){
           if(!value) return ''
           value = value.toString()
           return value.replace(/[^a-zA-Z ]/g, "")
        },  
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        }
        
    },

    created () {  
     
      this.getRewardCatalogList(); 
      //this.getUserGroupList();

    },
    methods: {   
      
      actionDelete(id){
         if (confirm('Are you sure you want to delete  ? ')) {
            this.removeRewardCatalogByIdGet(id);
         }
      },

      removeRewardCatalogByIdGet: function(id) { 
          RewardService.removeRewardCatalogByIdGet(id).then(resp => {  //console.log(resp);

            if(resp.message=='Success'){
                this.msg   = 'Delete Reward Catalog Successfully'; 
                this.color = 'success';  this.seen  = true; 
                this.getRewardCatalogList();                
              } else {
                this.msg   = !resp.message ? "Delete Reward Catalog Failed!" : resp.message; 
                this.color = 'warning'; this.seen  = true;                
              }
              setTimeout( () =>  this.seen=false , 5000); 
            
           }, error => {
            console.log('error');    
          });
      },  
      
      getUserGroupList: function() {
        TransactService.getUserGroupList().then(resp => {  //console.log(resp);     
          
            var groupSrc=[]; let ListUserGroup = {};
            var groupType = resp;
            for (var key in groupType) {
                var group = groupType[key];  
               // groupSrc.push({ value: group.id , text: group.groupUniqueNumber });
                ListUserGroup[group.id] = group.groupUniqueNumber;                
            }

            //this.optUserGroups = groupSrc;
            this.ListUserGroup = ListUserGroup;

        }, error => {
          this.loading = false;
        });
      },
      
      getRewardCatalogList: function() {
        RewardService.getRewardCatalogList().then(resp => {  //console.log(resp);
           this.items = resp.content; 
        }, error => {
          this.spinner = false;
        });
      },

    }    
 }; 

</script>
<style>
    .table{ hoverflow-y:scroll; }
</style>